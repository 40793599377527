import { PageProps } from "gatsby";

import { LoggedOutBar } from "../../components/AppBar";
import { LoginPageComonent } from "../../components/BankId/LoginPageComonent";
import GuidepostSimplified from "../../components/Rozcestnik/GuidepostSimplified";
import { SEO } from "../../components/SEO";
import withRedirectWhenLoggedIn from "../../highOrderComponents/withRedirectWhenLoggedIn";

export const AppBar = (): JSX.Element => <LoggedOutBar />;

function LoginPage(pageProps: PageProps): JSX.Element {
	if (process.env.GATSBY_FEATURE_IS_SIMPLE_GUIDEPOST_ENABLED === "true") {
		return <GuidepostSimplified pageProps={pageProps} />;
	} else {
		return <LoginPageComonent />;
	}
}

export default withRedirectWhenLoggedIn(LoginPage);

export const Head = (): JSX.Element => <SEO title="Přihlášení" />;
