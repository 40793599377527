import { navigate } from "gatsby";
import React, { ComponentType, useEffect } from "react";

import { useIsUserLoggedIn } from "../hooks/useIsUserLoggedIn";
export default function withRedirectWhenLoggedIn<
	T extends React.JSX.IntrinsicAttributes
>(PageComponent: ComponentType<T>): (props: T) => JSX.Element {
	return function withRedirectWhenLoggedIn(props: T): JSX.Element {
		const isLoggedUser = useIsUserLoggedIn();
		useEffect(() => {
			isLoggedUser && navigate("/smlouvy/");
		}, [isLoggedUser]);
		return <PageComponent {...props} />;
	};
}
