import { IMsalContext, useMsal } from "@azure/msal-react";
import { useState } from "react";
import { v4 as uuidv4 } from "uuid";

import { LoginButtonType } from ".";
import { getBankIdRedirectUri, loginRedirect } from "./useLogin.helpers";
import { navigate } from "gatsby";

interface useLoginProps {
	isDisabled: boolean;
	click: () => void;
}

// async function: determine if user if logged in
export const isLoggedInMsal = async (msal: IMsalContext) => {
	try {
		await msal.instance.acquireTokenSilent({
			scopes: ["openid", process.env.GATSBY_B2C_API_SCOPE],
		});
		return true;
	} catch (e) {
		return false;
	}
};

export default (journey: LoginButtonType): useLoginProps => {
	const msal = useMsal();
	const [isDisabled, setIsDisabled] = useState(false);

	const getBankIdAuthorizationUri = (): string => {
		// state=BankID... this must ALWAYS be present, 
		// exactly this part: "BankID". This is included in front door rewrite rule condition
		// if removed, bankId login/register will not work!!!
		return `${process.env.GATSBY_BANK_ID_BASE_URL as string}/auth?client_id=${process.env.GATSBY_BANK_ID_CLIENT_ID_LOGIN as string
			}&redirect_uri=${encodeURIComponent(
				getBankIdRedirectUri()
			)}&scope=${encodeURIComponent(
				"openid profile.name profile.email profile.phonenumber profile.birthdate"
			)}&response_type=code&state=BankID${uuidv4()}&nonce=${uuidv4()}&prompt=login&display=page&acr_values=loa3`;
	};

	const click = async () => {
		setIsDisabled(true);

		const isLoggedIn = await isLoggedInMsal(msal);
		if (isLoggedIn) {
			navigate("/smlouvy");
			return;
		}

		try {
			if (journey === "bank-id-direct") {
				window.location.href = getBankIdAuthorizationUri();
			} else {
				await loginRedirect(msal, journey);
			}
		} catch (error) {
			setIsDisabled(false);
		} finally {
			setIsDisabled(false);
		}
	};

	return {
		isDisabled,
		click,
	};
};
