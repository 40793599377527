import { Stack } from "@mui/material";
import useIsDesktop from "desktop/useIsDesktop";
import { PageProps } from "gatsby";

import { LoggedOutBar } from "../../components/AppBar";
import BankIcons from "../../components/BankIcons";
import DarkBox from "../../components/InfoBox/DarkBox";
import { BackLink } from "../../components/Link/InternalLink";
import LoginButton from "../../components/LoginButton";
import GuidepostSimplified from "../../components/Rozcestnik/GuidepostSimplified";
import { SEO } from "../../components/SEO";
import {
	BodyTextM,
	BodyTextS,
	HeadingL,
	HeadingS,
} from "../../components/Typography";
import withRedirectWhenLoggedIn from "../../highOrderComponents/withRedirectWhenLoggedIn";
import cs from "../../translations/cs.json";

export const AppBar = (): JSX.Element => <LoggedOutBar />;

function RegistrationPage(pageProps: PageProps): JSX.Element {
	const isD = useIsDesktop();

	if (process.env.GATSBY_FEATURE_IS_SIMPLE_GUIDEPOST_ENABLED === "true") {
		return <GuidepostSimplified pageProps={pageProps} />;
	}

	return (
		<>
			<Stack
				sx={{
					mt: isD ? 3 : 0,
					p: { md: 5 },
					boxShadow: (theme) => ({
						md: theme.customShadows.close,
					}),
					backgroundColor: "white.main",
				}}
			>
				<BackLink mb={5} to="/prihlaseni-nebo-registrace" />
				<HeadingL>Registrace</HeadingL>
				<Stack spacing={5}>
					<BodyTextM>Vyberte si jednu z možností</BodyTextM>
					<DarkBox>
						<HeadingS withoutScale>Rychlá a pohodlná registrace</HeadingS>
						<BodyTextM
							dangerouslySetInnerHTML={{
								__html: cs.global.bankIdRecommendation,
							}}
						></BodyTextM>
						<LoginButton journey="bank-id" sx={{ mt: 4 }} />
						<BankIcons />
					</DarkBox>
					{process.env.GATSBY_FEATURE_IS_EMAIL_REGISTRATION_ENABLED ===
						"true" && (
						<DarkBox>
							<HeadingS withoutScale>Manuální vyplnění</HeadingS>
							<BodyTextS>
								Vyplníte osobní údaje, ověříte svůj e-mail a telefon.
							</BodyTextS>
							<LoginButton
								journey="local-registration"
								sx={{ alignSelf: { md: "flex-start" } }}
							/>
						</DarkBox>
					)}
				</Stack>
			</Stack>
		</>
	);
}

export default withRedirectWhenLoggedIn(RegistrationPage);

export const Head = (): JSX.Element => (
	<SEO
		description="Nejrychlejší registrace je přes Vaši banku. Automaticky předvyplní údaje."
		title="Registrace"
	/>
);
