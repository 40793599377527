import { useIsAuthenticated, useMsal } from "@azure/msal-react";

// hook: determine if user if logged in
export const useIsUserLoggedIn = (): boolean => {
	const { instance } = useMsal();
	const isAuth = useIsAuthenticated();
	const isUserLoggedIn = !!instance.getActiveAccount() && isAuth;

	return isUserLoggedIn;
};
